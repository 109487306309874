import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {useTranslations} from 'next-intl';
import Head from 'next/head';
import Image from 'next/image';
import React from 'react';
import type {NotFoundProps} from './types/NotFound';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    container: {
        marginTop: theme.spacing(6),
        zIndex: 1,
        position: 'relative',
    },
    alert: {
        zIndex: 1,
        position: 'relative',
    },
    alertTitle: {
        fontWeight: theme.typography.h4.fontWeight,
        marginTop: theme.typography.h4.marginTop,
    },
    background: {
        zIndex: -1,
    },
    card: {
        zIndex: 1,
        maxWidth: '500px',
        margin: '50px auto',
    },
}));

const hax0r = /php|shell|zip|sql|\.ini|union|select|log|\.cgi|admin|manager?|dashboard|login|register/gi;

let NotFound: React.FC<NotFoundProps> = ({error, router}) => {
    const t = useTranslations('components.notFound');
    const classes = useStyles();
    let noHack = null;
    if (router && hax0r.test(router.asPath)) {
        noHack = (
            <CardContent>
                <Typography color="textSecondary" variant="subtitle2">
                    We love hackers, please don't break our website &lt;3
                </Typography>
            </CardContent>
        );
    }

    return (
        <div className={classes.root}>
            <Head>
                <title>Not found</title>
                <meta name="description" content="Reservation was not found" />
            </Head>
            <div>
                <Image
                    src="https://cdn.snapcheck.in/static/bitart/bitart-background-waterfall.gif"
                    alt="A waterfall with domiciles on the cliff side"
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    className={classes.background}
                />
            </div>

            <Container maxWidth="sm" className={classes.container}>
                <Card className={classes.card} elevation={5}>
                    <CardHeader
                        title={t('title')}
                        titleTypographyProps={{
                            variant: 'h3',
                        }}
                        subheader={error || t('reservationNotFound')}
                    />
                    {noHack}
                    {router?.asPath === '/' && (
                        <CardContent>
                            <Typography color="textSecondary" variant="subtitle2">
                                {t('homePage', {
                                    anchor: (children) => <a href="https://autohost.ai">{children}</a>,
                                })}
                            </Typography>
                        </CardContent>
                    )}
                </Card>
            </Container>
        </div>
    );
};
export {NotFound};
export default NotFound;
